import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FundUsage from "../../components/fundUsage/FundUsage";
import fileImage from "../../assets/home/file.png";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";
import CardSectionWeb from "../../components/cardSection/CardSectionWeb";
import ContentSectionMobille from "../../components/contentSection/ContentSectionMobille";
import ContentSectionWeb from "../../components/contentSection/ContentSectionWeb";
import InfoSectionMobile from "../../components/infoSection/InfoSectionMobile";
import InfoSectionWeb from "../../components/infoSection/InfoSectionWeb";
import HomeSectionMobile from "../../components/homeFrontSection/HomeSectionMobile";
import HomeSectionWeb from "../../components/homeFrontSection/HomeSectionWeb";

const HomePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isSmallScreenContent = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      {/* Section 1 */}
      {/* <Box
        sx={{
          marginBottom: {
            xs: "30px",
            sm: "50px",
            md: "66px",
          },
          width: "100%",
          height: {
            xs: "600px",
            sm: "650px",
            md: "850px",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: {
              xs: "80%",
              sm: "90%",
              md: "771px",
            },
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            top: "0",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              paddingBottom: "90px",
              width: "55%",
              paddingLeft: { xs: "46px", sm: "62px", md: "50px" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#23ECA2",
                fontWeight: "700",
                fontSize: { xs: "30px", sm: "40px", md: "40px" },
                lineHeight: "65px",
                width: { xs: "40%", sm: "40%", md: "91%" },
              }}
            >
              JOIN OUR COMMUNITY <br />
              MANY INVESTORS AND START YOUR JOURNEY TOWARDS LONG-TERM WEALTH
              CREATION.
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                width: "75%",
                zIndex: "10",
              }}
            >
              <Link
                to={mobileApplicationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#2DDEF8",
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "600",
                    borderRadius: "6px",
                    p: "10px 20px",
                  }}
                >
                  DOWNLOAD APP
                </Button>
              </Link>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2DDEF8",
                  color: "#000000",
                  fontSize: "20px",
                  fontWeight: "600",
                  borderRadius: "6px",
                  p: "0px 25px",
                }}
              >
                INVEST NOW
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: {
              xs: 0,
              sm: 0,
              md: 50,
            },
            width: "100%",
            height: "282.93px",
            backgroundImage: `url(${graphbottom})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: "1",
          }}
        ></Box>

        <Box
          sx={{
            position: "absolute",
            right: {
              xs: "3%", // Center horizontally on small screens
              sm: "3%",
              md: "5.5%", // Adjust left position for larger screens
            },
            transform: {
              xs: "translateY(5%)", // Center hand image on small screens
              sm: "translateY(0%)", // Center hand image on small screens
              md: "translateY(2%)", // Do not apply translate for larger screens
            },
            width: {
              xs: "50%", // Smaller width for small screens
              md: "100%", // Larger width for larger screens
            },
            maxWidth: "564.74px",
            height: "685.22px",
            backgroundImage: `url(${rightImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Box> */}

      {/* Front Page - Section 1 */}
      {isSmallScreen ? <HomeSectionMobile /> : <HomeSectionWeb />}

      {/* ABOUT OUR COMPANY - Section 2 */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: { xs: "50px", md: "80px" },
          padding: { xs: "0px 40px", md: "0px 40px" },
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight={"600"}
          fontSize={{ xs: "14px", sm: "18px", md: "26px" }}
          gutterBottom
          sx={{
            textDecoration: "underline",
            textDecorationColor: "#2DDEF8",
            textUnderlineOffset: { xs: "10px", md: "15px" },
            textDecorationThickness: { xs: "3px", md: "5px" },
          }}
        >
          ABOUT OUR COMPANY
        </Typography>

        {/* Content and Image Section */}
        <Grid
          container
          spacing={{ xs: 3, md: 4 }}
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: { xs: "20px", md: "40px" },
          }}
        >
          {/* Image Box */}
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Box
                component="img"
                src={fileImage}
                alt="Illustration"
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  maxWidth: { xs: "260.68px", md: "442.56px" },
                  height: { xs: "188.08px", md: "auto" },
                }}
              />
            </Box>
          </Grid>

          {/* Text Content */}
          <Grid item xs={12} md={5}>
            <Typography
              variant="body1"
              sx={{
                textAlign: { xs: "center", sm: " center", md: "left" },
                fontSize: { xs: "12px", sm: " 16px", md: "20px" },
                color: "#FFFFFF",
                lineHeight: { xs: "15px", sm: " 18px", md: 1.6 },
                fontWeight: "500",
              }}
            >
              We are dedicated as the only platform using advanced algorithms
              within a vast and growing global ecosystem to match
              corporate-seeking capital with highly relevant opportunities
              anywhere in the world that align with your objectives.
              <br />
              <br />
              We built our technology platform to bridge the barrier. Our
              software allows us to achieve the scale of institutions without
              the bureaucracy. By combining our technology and investment
              expertise, we are pioneering a new model to build you a better
              portfolio.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Choose Triangle - File Section 3 */}
      {isSmallScreenContent ? <ContentSectionMobille /> : <ContentSectionWeb />}

      {/* OUR 10 MIN - PROMISE - File Section 4 */}
      {isSmallScreenContent ? <InfoSectionMobile /> : <InfoSectionWeb />}

      {/* FundUsage - File Section 5 */}
      <FundUsage />

      {/* carousel - File Section 6 */}
      <Box
        sx={{
          marginBottom: { xs: "20px", sm: "15px", md: "15px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : <CardSectionWeb />}
      </Box>
    </Box>
  );
};

export default HomePage;

import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profileImage1 from "../../assets/carouselImages/image1.png";
import profileImage2 from "../../assets/carouselImages/image2.png";
import profileImage3 from "../../assets/carouselImages/image3.png";
import profileImage4 from "../../assets/carouselImages/image4.png";
import profileImage5 from "../../assets/carouselImages/image5.png";

const CardSectionMobile = () => {
  const sliderRef = useRef(null);

  const users = [
    {
      name: "GAURAV BARJATYA",
      location: "MAHARASHTRA",
      image: profileImage1,
      content:
        "Our commitment to transparency means we thoroughly outline our fees and principles for the highest level of openness and security.",
    },
    {
      name: "JAGDISH YADAV",
      location: "MADHYA PRADESH",
      image: profileImage2,
      content:
        "I have made investments in mutual funds based on the suggestions given by TRIANGLE Invest. I am very much satisfied with Swan’s suggestions and services.",
    },
    {
      name: "RAM PAWAR",
      location: "UTTAR PRADESH",
      image: profileImage3,
      content:
        "I appreciate the services of TRIANGLE Invest. We have been together for many years. They have good facilities and there is transparency in their work.",
    },
    {
      name: "AASHISH MANDLOI",
      location: "MAHARASHTRA",
      image: profileImage4,
      content:
        "I have been a client of TRIANGLE for 6 years now and I can definitely say that my funds and investments are in completely safe hands.",
    },
    {
      name: "RENA JADHAV",
      location: "MADHYA PRADESH",
      image: profileImage5,
      content:
        "On a scale of 1-10, I would rate the services of TRIANGLE as an 8. I am very likely to stay with TRIANGLE on a long-term basis based on their recommendations.",
    },
  ];

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const SampleNextArrow = () => {
    return (
      <Box
        onClick={previous}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(100%)",
          zIndex: 2,
          cursor: "pointer",
          width: "7.7px",
          height: "15.38px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "50px" }}>‹</Typography>
      </Box>
    );
  };

  const SamplePrevArrow = () => {
    return (
      <Box
        onClick={next}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(100%)",
          zIndex: 2,
          cursor: "pointer",
          width: "7.7px",
          height: "15.38px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "50px" }}>›</Typography>
      </Box>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <style>
        {`
        .slick-dots {
          bottom: 5px !important;
        }
        .slick-dots li {
          margin: 0 2px !important;
        }
        .slick-dots li button:before {
          font-size: 8px !important;
          color: #000000 !important;
          opacity: 0.3 !important;
        }
        .slick-dots li.slick-active button:before {
          opacity: 1 !important;
          color: #000000 !important;
        }
      `}
      </style>
      <Box
        sx={{
          width: "100%",
          height: "278.53px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(247.72deg, #5FBA99 3.39%, #13FFA9 53.48%, #92FFD7 100%)",
            width: "100%",
            height: "219.05px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            zIndex: "1",
            flexDirection: "column",
            opacity: "80%",
          }}
        >
          <Slider ref={sliderRef} {...settings} style={{ width: "100%" }}>
            {users.map((user, index) => (
              <div key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px",
                  }}
                >
                  {/* Center Large Circle */}
                  <Box
                    sx={{
                      width: "169px",
                      height: "169px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: "translateY(-0%)",
                      zIndex: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={user.image}
                      alt={user.name}
                      sx={{
                        width: "138.1px",
                        height: "138.1px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        boxShadow: "0 0 30px rgba(0, 255, 251, 0.5)",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      color: "#101011",
                      textAlign: "center",
                      width: { xs: "85%", sm: "65%", md: "54%" },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#101011",
                        fontWeight: "800",
                        margin: 0,
                        fontSize: { xs: "16px", sm: "22px", md: "24px" },
                        lineHeight: {
                          xs: "19.36px",
                          sm: "28px",
                          md: "29.05px",
                        },
                      }}
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#101011",
                        fontWeight: "600",
                        fontSize: { xs: "10px", sm: "15px", md: "16px" },
                        lineHeight: {
                          xs: "12.1px",
                          sm: "19.38px",
                          md: "19.38px",
                        },
                      }}
                    >
                      {user.location}
                    </Typography>
                    <Box
                      sx={{
                        marginTop: { xs: "15px", sm: "50px", md: "45px" },
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{
                          width: "100%",
                          maxWidth: "300.54px",
                          height: "80px",
                          color: "#000000",
                          fontWeight: "400",
                          fontSize: { xs: "10px", sm: "15px", md: "16px" },
                          lineHeight: {
                            xs: "12.1px",
                            sm: "18px",
                            md: "19.38px",
                          },
                        }}
                      >
                        {user.content}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default CardSectionMobile;

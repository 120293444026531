import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profileImage1 from "../../assets/carouselImages/image1.png";
import profileImage2 from "../../assets/carouselImages/image2.png";
import profileImage3 from "../../assets/carouselImages/image3.png";
import profileImage4 from "../../assets/carouselImages/image4.png";
import profileImage5 from "../../assets/carouselImages/image5.png";

const CardSectionWeb = () => {
  const sliderRef = useRef(null);

  const users = [
    {
      name: "GAURAV BARJATYA",
      location: "MAHARASHTRA",
      image: profileImage1,
      content:
        "Our commitment to transparency means we thoroughly outline our fees and principles for the highest level of openness and security.",
    },
    {
      name: "JAGDISH YADAV",
      location: "MADHYA PRADESH",
      image: profileImage2,
      content:
        "I have made investments in mutual funds based on the suggestions given by TRIANGLE Invest. I am very much satisfied with Swan’s suggestions and services.",
    },
    {
      name: "RAM PAWAR",
      location: "UTTAR PRADESH",
      image: profileImage3,
      content:
        "I appreciate the services of TRIANGLE Invest. We have been together for many years. They have good facilities and there is transparency in their work.",
    },
    {
      name: "AASHISH MANDLOI",
      location: "MAHARASHTRA",
      image: profileImage4,
      content:
        "I have been a client of TRIANGLE for 6 years now and I can definitely say that my funds and investments are in completely safe hands.",
    },
    {
      name: "RENA JADHAV",
      location: "MADHYA PRADESH",
      image: profileImage5,
      content:
        "On a scale of 1-10, I would rate the services of TRIANGLE as an 8. I am very likely to stay with TRIANGLE on a long-term basis based on their recommendations.",
    },
    
  ];

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <Box
      sx={{
        background:
          "linear-gradient(247.72deg, #5FBA99 3.39%, #13FFA9 53.48%, #92FFD7 100%)",
        width: "100%",
        height: { xs: "420px", sm: "550px", md: "602.52px" },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        marginTop: "80px",
        opacity: "80%",
      }}
    >
      {/* Navigation Buttons */}
      <Box
        onClick={previous}
        sx={{
          position: "absolute",
          left: { xs: "10px", sm: "20px", md: "30px" },
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          cursor: "pointer",
          width: { xs: "30px", sm: "40px", md: "50px" },
          height: { xs: "30px", sm: "40px", md: "50px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: { xs: "30px", sm: "50px", md: "70px" } }}>
          ‹
        </Typography>
      </Box>

      <Box
        onClick={next}
        sx={{
          position: "absolute",
          right: { xs: "10px", sm: "20px", md: "30px" },
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          cursor: "pointer",
          width: { xs: "30px", sm: "40px", md: "50px" },
          height: { xs: "30px", sm: "40px", md: "50px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: { xs: "30px", sm: "50px", md: "70px" } }}>
          ›
        </Typography>
      </Box>

      <Slider ref={sliderRef} {...settings} style={{ width: "100%" }}>
        {users.map((user, index) => (
          <div key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
              }}
            >
              {/* Circle Images Row */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Left Two Circles */}
                {[3, 4].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: { xs: "70px", sm: "100px", md: "128px" },
                      height: { xs: "70px", sm: "100px", md: "128px" },
                      backgroundColor: "white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "0 0 50px rgba(0, 255, 251, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={users[(index + i) % users.length].image}
                      alt={`Circle ${i + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}

                {/* Center Large Circle */}
                {[0].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: { xs: "120px", sm: "150px", md: "219px" },
                      height: { xs: "120px", sm: "150px", md: "219px" },
                      backgroundColor: "white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "0 0 50px rgba(0, 255, 251, 0.5)",
                      transform: "translateY(20%)",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={users[(index + i) % users.length].image}
                      alt={`Circle ${i + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}

                {/* Right Two Circles */}
                {[1, 2].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: { xs: "70px", sm: "100px", md: "128px" },
                      height: { xs: "70px", sm: "100px", md: "128px" },
                      backgroundColor: "white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "0 0 50px rgba(0, 255, 251, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={users[(index + i) % users.length].image}
                      alt={`Circle ${i + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}
              </Box>

              {/* Info Box */}
              <Box
                sx={{
                  backgroundColor: "#AAD9C8",
                  width: { xs: "85%", sm: "65%", md: "54%" },
                  maxWidth: "823px",
                  height: { xs: "200px", sm: "280px", md: "281.24px" },
                  textAlign: "center",
                  padding: "20px",
                  borderRadius: "39px",
                }}
              >
                <Box
                  sx={{
                    color: "#101011",
                    mt: { xs: "20px", sm: "35px", md: "50px" },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#101011",
                      fontWeight: "800",
                      margin: 0,
                      fontSize: { xs: "20px", sm: "22px", md: "24px" },
                      lineHeight: { xs: "27px", sm: "28px", md: "29.05px" },
                    }}
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#101011",
                      fontWeight: "600",
                      fontSize: { xs: "14px", sm: "15px", md: "16px" },
                      lineHeight: {
                        xs: "19.38px",
                        sm: "19.38px",
                        md: "19.38px",
                      },
                    }}
                  >
                    {user.location}
                  </Typography>
                  <Box
                    sx={{
                      marginTop: { xs: "25px", sm: "50px", md: "45px" },
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        width: "100%",
                        maxWidth: "426.65px",
                        height: "95px",
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: { xs: "14px", sm: "15px", md: "16px" },
                        lineHeight: { xs: "17px", sm: "18px", md: "19.38px" },
                      }}
                    >
                      {user.content}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default CardSectionWeb;

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import phoneImage from "../../assets/about/phoneScreen.png";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const MobileSectionMobile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "389.31px",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "239.4px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          background:
            "linear-gradient(90deg, #1ECB8B 0%, rgba(152, 241, 208, 0.83902) 100%)",
          overflowX: "hidden",
        }}
      >
        {/* Left side text  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-evenly",
            justifyContent: "center",
            height: "100%",
            alignItems: "flex-start",
            position: "relative",
            paddingLeft: { xs: "15px", sm: "20px", md: "92px" },
            // width: "40%",
            width: "auto",
            maxWidth: "44%",
            rowGap: "15px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#000000",
              fontWeight: "600",
              fontSize: { xs: "24px" },
              maxWidth: { xs: "83%" },
              textAlign: { xs: "left", md: "left" },
              lineHeight: { xs: "29.05px" },
            }}
          >
            Ready to turn dreams <br />
            into reality?
          </Typography>{" "}
          <Box>
            <Link
              to={mobileApplicationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                sx={{
                  // width: { xs: "150px", sm: "200px", md: "230px" },
                  backgroundColor: "#000000",
                  color: "#2DDEF8",
                  fontSize: { xs: "14px", sm: "16px", md: "20px" },
                  fontWeight: "600",
                  borderRadius: "6px",
                  zIndex: "1000",
                  p: { xs: "15px 15px", sm: "15px 20px", md: "15px 20px" },
                }}
              >
                DOWNLOAD APP
              </Button>
            </Link>
          </Box>
        </Box>

        {/* Phone image positioned partially above the green background  */}
        {/* <Box
          component="img"
          src={phoneImage}
          alt="Hand holding phone"
          sx={{
            overflowX: "hidden",
            position: "absolute",
            zIndex: "1",
            width: { xs: "auto" },
            maxWidth: "305px", 
            height: "368.31px",
            right: "0px",
            transform: "translateY(-18%)",
          }}
        ></Box> */}
        <Box
          component="img"
          src={phoneImage}
          alt="Hand holding phone"
          sx={{
            overflowX: "hidden",
            position: "absolute",
            zIndex: "1",
            width: { xs: "auto" },
            maxWidth: "300px", 
            height: "385.31px",
            right: "0px",
            transform: "translateY(-19%)",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MobileSectionMobile;

import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import circleImage1 from "../../assets/contentSection/image 1.png";
import circleImage2 from "../../assets/contentSection/image 2.png";
import circleImage3 from "../../assets/contentSection/image 3.png";
import circleImage4 from "../../assets/contentSection/image 4.png";

// Custom button style to mimic the gradient effect and required dimensions
const GradientButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #101011 0%, #21EDA2 100%)",
  color: "#FFFFFF",
  borderRadius: "54.5px",
  padding: "0px 10px",
  width: "100%",
  maxWidth: "721px",
  height: "79px",
  justifyContent: "end",
  columnGap: "25px",
  display: "flex",
  alignItems: "center",
  opacity: 1,
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "linear-gradient(90deg, #101011 0%, #1FBF8A 100%)",
  },
}));

const GradientButton1 = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #21EDA2 0%, #101011 100%)",
  color: "#FFFFFF",
  borderRadius: "54.5px",
  padding: "0px 10px",
  width: "100%",
  maxWidth: "721px",
  height: "79px",
  justifyContent: "flex-start",
  columnGap: "25px",
  display: "flex",
  alignItems: "center",
  opacity: 1,
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "linear-gradient(90deg, #1FBF8A 0%, #101011 100%)",
  },
}));

const Circle = styled(Box)(({ theme, imageUrl }) => ({
  width: "67px",
  height: "67px",
  backgroundColor: "#AAEED6",
  borderRadius: "50%",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "40.74px 40.74px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const ContentSectionWeb = () => {
  return (
    <Box
      sx={{
        padding: { sm: " 0px 60px", md: "0px 60px" },
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        align="center"
        fontWeight={"600"}
        fontSize={{ xs: "14px", sm: "24px", md: "26px" }}
        gutterBottom
        sx={{
          textDecoration: "underline",
          textDecorationColor: "#2DDEF8",
          textUnderlineOffset: { xs: "10px", md: "15px" },
          textDecorationThickness: { xs: "3px", md: "5px" },
        }}
      >
        Why Should You Choose Triangle
      </Typography>

      {/* Main Section */}
      <Grid container spacing={4} alignItems="center" marginTop={"50px"}>
        {/* Left: Gradient Buttons */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  maxWidth: "498px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                One single destination for your entire investment needs
              </Typography>
              <Circle imageUrl={circleImage1} />
            </GradientButton>

            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  maxWidth: "373.37px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "right",
                  textTransform: "none",
                }}
              >
                Get your own Personal Investment Coach
              </Typography>
              <Circle imageUrl={circleImage2} />
            </GradientButton>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <GradientButton1>
              <Circle imageUrl={circleImage3} />
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  maxWidth: "356.69px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                World class in-house Research Team
              </Typography>
            </GradientButton1>

            <GradientButton1>
              <Circle imageUrl={circleImage4} />
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  maxWidth: "266.04px",
                  fontWeight: "600",
                  fontSize: { xs: "12px", sm: "20px", md: "24px" },
                  lineHeight: { xs: "14.52px", sm: " 25px", md: "29.05px" },
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                Most trustable platform
              </Typography>
            </GradientButton1>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentSectionWeb;

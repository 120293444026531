import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import frontInvestImage from "../../assets/invest/frontInvestImage.png";
import frontInvestMobile from "../../assets/invest/frontInvestMobile.png";
import portfolioimage from "../../assets/invest/portfolioimage.png";
import OverServicesMobile from "../../components/overServices/OverServicesMobile";
import OverServicesWeb from "../../components/overServices/OverServiceWeb";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";
import CardSectionWeb from "../../components/cardSection/CardSectionWeb";
import FundUsage from "../../components/fundUsage/FundUsage";
import { Link } from "react-router-dom";
import { mobileApplicationUrl } from "../../constant";

const InvestPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      {/* Front page - Section 1 */}
      <Box
        sx={{
          width: "100%",
          height: "702.84px",
          backgroundImage: {
            xs: `url(${frontInvestMobile})`,
            sm: `url(${frontInvestImage})`,
            md: `url(${frontInvestImage})`,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: { xs: "center", sm: "flex-start", md: "flex-start" },
          justifyContent: { xs: "space-around", sm: "center", md: "center" },
          flexDirection: "column",
          gap: { xs: "200px", sm: "60px", md: "60px" },
        }}
      >
        <Typography
          variant="h4"
          align={isSmallScreen ? "center" : "left"}
          sx={{
            color: "#23ECA2",
            fontWeight: "600",
            fontSize: { xs: "20px", sm: "40px", md: "40px" },
            lineHeight: { xs: "30px", sm: "50px", md: "55px" },
            width: { xs: "53%", sm: "45%", md: "30%" },
            // maxWidth: "387px",
            paddingLeft: { xs: "0px", sm: "62px", md: "87.1px" },
          }}
        >
          WE ARE BREAKING GROUND WITH <br /> OUR TECHNOLOGY.
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "20px", sm: "25px", md: "30px" },
            paddingLeft: { xs: "0px", sm: "62px", md: "87.1px" },
          }}
        >
          <Link
            to={mobileApplicationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2DDEF8",
                color: "#000000",
                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                fontWeight: "600",
                borderRadius: "6px",
                p: "10px 10px",
              }}
            >
              DOWNLOAD APP
            </Button>
          </Link>
          <Button
            variant="contained"
            component={Link}
            to="/contact"
            sx={{
              backgroundColor: "#2DDEF8",
              color: "#000000",
              fontSize: { xs: "16px", sm: "18px", md: "20px" },
              fontWeight: "600",
              borderRadius: "6px",
              p: "10px 15px",
            }}
          >
            INVEST NOW
          </Button>
        </Box>
      </Box>

      {/* FundUsage -  Section 3 */}
      <FundUsage />

      {/* PORTFOLIO -  Section 4 */}
      <Box
        sx={{
          marginBottom: { xs: "60px", md: "80px" },
          marginTop: { xs: "0px", md: "100px" },
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight={"600"}
          fontSize={{ xs: "14px", sm: "18px", md: "24px" }}
          gutterBottom
          sx={{
            textDecoration: "underline",
            textDecorationColor: "#2DDEF8",
            textUnderlineOffset: { xs: "10px", md: "15px" },
            textDecorationThickness: { xs: "3px", md: "5px" },
          }}
        >
          Our PORTFOLIO
        </Typography>
        <Box
          component="img"
          src={portfolioimage}
          alt="Portfolio Image"
          sx={{
            width: "296px",
            height: "658px",
            gap: "0px",
            opacity: "0px",
            display: "block",
            margin: "0 auto",
            marginTop: { xs: "60px", md: "70px" },
          }}
        />
      </Box>

      {/* Over Services -  Section 5 */}
      {isSmallScreen ? <OverServicesMobile /> : <OverServicesWeb />}

      {/* carousel - File Section 6 */}
      <Box
        sx={{
          marginBottom: { xs: "20px", sm: "15px", md: "15px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : <CardSectionWeb />}
      </Box>
    </Box>
  );
};

export default InvestPage;

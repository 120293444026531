import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardSectionWeb from "../../components/cardSection/CardSectionWeb";
import MaskGroup from "../../assets/about/MaskGroup.png";
import MaskGroup2 from "../../assets/about/Maskgroup2.png";
import CardSectionMobile from "../../components/cardSection/CardSectionMobile";
import MobileSectionMobile from "../../components/aboutMobileSection/MobileSectionMobile";
import MobileSectionWeb from "../../components/aboutMobileSection/MobileSectionWeb";

const AboutPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
      }}
    >
      {/* Front page - Section 1 */}
      <Box
        sx={{
          width: "100%",
          height: { xs: "655px", md: "588px" },
          backgroundImage: `url(${MaskGroup})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", sm: "left", md: "left" },
        }}
      >
        <Typography
          variant="h4"
          align={isSmallScreen ? "center" : "left"}
          sx={{
            color: "white",
            fontWeight: "600",
            fontSize: { xs: "40px", sm: "40px", md: "40px" },
            lineHeight: { xs: "30px", sm: "50px", md: "55px" },
            width: "100%",
            // maxWidth: "387px",
            paddingLeft: { xs: "0px", sm: "62px", md: "87.1px" },
            textDecoration: "underline",
            textDecorationColor: "#FFFFFF",
            textUnderlineOffset: { xs: "10px", md: "15px" },
            textDecorationThickness: { xs: "3px", md: "5px" },
          }}
        >
          ABOUT US
        </Typography>
      </Box>

      {/* About Our Company - Section 2 */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: { xs: "15px", md: "66px" },
          padding: { xs: "0px 40px", md: "0px 40px" },
          marginTop: { xs: "50px", md: "100px" },
        }}
      >
        {/* Image Section */}
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            gap: { xs: "6px", md: "16px" },
          }}
        >
          {/* Image Box */}
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Box
                component="img"
                src={MaskGroup2}
                alt="Illustration"
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  maxWidth: { xs: "268.74px", md: "541.56px" },
                  height: { xs: "189.46px", md: "auto" },
                }}
              />
            </Box>
          </Grid>

          {/* Text Content */}
          <Grid item xs={12} md={5}>
            <Box
              align={isSmallScreen ? "center" : "left"}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!isSmallScreen ? (
                <Typography
                  variant="h5"
                  fontWeight={"600"}
                  fontSize={{ xs: "24px", md: "34px" }}
                  gutterBottom
                  sx={{
                    textDecoration: "underline",
                    textDecorationColor: "#2DDEF8",
                    textUnderlineOffset: { xs: "10px", md: "15px" },
                    textDecorationThickness: { xs: "3px", md: "5px" },
                  }}
                >
                  About Our Company
                </Typography>
              ) : (
                ""
              )}
              {/* <Typography
                variant="h5"
                fontWeight={"600"}
                fontSize={{ xs: "24px", md: "34px" }}
                gutterBottom
              >
                About Our Company
              </Typography> */}
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "12px", md: "20px" },
                  color: "#FFFFFF",
                  lineHeight: { xs: "15px", md: 1.6 },
                  fontWeight: "500",
                }}
              >
                we are dedicated the only platform using advanced algorithms
                within a vast and growing global ecosystem to match
                corporate-seeking capital with highly relevant anywhere in the
                world.that are in accordance with your needs in relation to
                objectives.
                <br />
                <br />
                We built our technology platform to bridge the barrier. Software
                allows us to achieve the scale of institutions without the
                bureaucracy. Combining our technology and investment expertise,
                we are pioneering a new model to build you a better portfolio.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Ready to turn dreams into reality - Section 3 */}
      {isSmallScreen ? <MobileSectionMobile /> : <MobileSectionWeb />}

      {/*  Our vision - Section 4 */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: { xs: "70px", sm: "100px", md: "100px" },
          padding: { xs: "0px 40px", md: "0px 40px" },
          marginTop: { xs: "40px", md: "100px" },
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            gap: { xs: "5px", sm: "100px", md: "240px" },
          }}
        >
          {/* Text Content - Our vision*/}
          <Grid item xs={12} sm={4} md={3.3}>
            <Box
              align={isSmallScreen ? "center" : "left"}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "0px" },
              }}
            >
              <Typography
                variant="h5"
                fontWeight={"600"}
                fontSize={{ xs: "16px", sm: "36px", md: "50px" }}
                lineHeight={{ xs: "19.36px", md: "60.51px" }}
                gutterBottom
              >
                Our vision
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "12px", sm: "16px", md: "20px" },
                  lineHeight: { xs: "14.52px", md: "24.2px" },
                  color: "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                Empower individuals to realize optimal returns and reach their
                financial aspirations through strategic investment plans,
                fostering a consistent passive income that combines savings with
                robust growth.
              </Typography>
            </Box>
          </Grid>

          {/* Text Content - Our mission */}
          <Grid item xs={12} sm={4} md={3.3}>
            <Box
              align={isSmallScreen ? "center" : "left"}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={"600"}
                fontSize={{ xs: "16px", sm: "36px", md: "50px" }}
                lineHeight={{ xs: "19.36px", md: "60.51px" }}
                gutterBottom
              >
                Our mission
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "12px", sm: "16px", md: "20px" },
                  lineHeight: { xs: "14.52px", md: "24.2px" },
                  color: "#FFFFFF",
                  fontWeight: "500",
                }}
              >
                To lead the industry in curating personalized portfolios,
                fine-tuning investments, and providing exceptional returns,
                empowering clients to maximize the potential gains from their
                investments.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* carousel - File Section 5 */}
      <Box
        sx={{
          marginBottom: { xs: "20px", sm: "15px", md: "15px" },
        }}
      >
        {isSmallScreen ? <CardSectionMobile /> : <CardSectionWeb />}
      </Box>
    </Box>
  );
};

export default AboutPage;

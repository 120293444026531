import React from "react";
import { Box, Typography } from "@mui/material";
import phoneImage from "../../assets/home/GroupMobile.png";

const FundUsage = () => {
  return (
    <Box
      sx={{
        marginTop: { xs: "0px", sm: "0px", md: "40px", lg: "50px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: { xs: "427px", sm: "100%", md: "1163.55px" },
            height: { xs: "427px", sm: "700px", md: "900px" },
          }}
        >
          <Typography
            variant="h4"
            align="center"
            fontWeight={"600"}
            fontSize={{ xs: "14px", sm: "20px", md: "26px" }}
            gutterBottom
            sx={{
              textDecoration: "underline",
              textDecorationColor: "#2DDEF8",
              textUnderlineOffset: { xs: "10px", md: "15px" },
              textDecorationThickness: { xs: "3px", md: "5px" },
            }}
            paddingTop={{ xs: "20px", sm: "25px", md: "30px" }}
            paddingBottom={{ xs: "40px", sm: "35px", md: "70px" }}
          >
            Fund Usage
          </Typography>

          {/* Background Circle */}
          <Box
            sx={{
              position: "absolute",
              width: { xs: "100%", sm: "90%", md: "100%" },
              height: { xs: "400px", sm: "750px", md: "970.17px" },
              maxWidth: "970.17px",
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(60, 217, 159, 0.4) 0%, rgba(152, 241, 208, 0.335608) 42.39%, rgba(89, 223, 174, 0.304178) 69.69%, rgba(0, 0, 0, 0.08) 100%)",
              borderRadius: "50%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></Box>

          {/* Mobile Image */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              component="img"
              src={phoneImage}
              alt="Illustration"
              sx={{
                width: "100%",
                maxWidth: { xs: "155.73px", sm: "300px", md: "353.41px" },
                height: { xs: "294.24px", sm: "auto", md: "667.75px" },
                zIndex: "1",
              }}
            />
          </Box>

          {/* Content Box - Top Left */}
          <Box
            sx={{
              position: "absolute",
              width: { xs: "122px", sm: "30%", md: "285.99px" },
              height: { xs: "35.56px", sm: "70px", md: "79.78px" },
              backgroundColor: "#FFFFFF",
              borderRadius: { xs: "5px", md: "18px" },
              border: {
                xs: "4px solid #00FFA3",
                sm: "8px solid #00FFA3",
                md: "10px solid #00FFA3",
              },
              top: { xs: "120px", sm: "180px", md: "220px" },
              left: { xs: "35px", sm: "120px", md: "235px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1",

              //? padding: "10px",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={"600"}
              fontSize={{ xs: "14px", sm: "24px", md: "32px" }}
              gutterBottom
              color="#0E0E0E"
            >
              IT COMPANY
            </Typography>
          </Box>

          {/* Content Box - Top Right */}
          <Box
            sx={{
              position: "absolute",
              width: { xs: "149.14px", sm: "35%", md: "347.54px" },
              height: { xs: "35.56px", sm: "70px", md: "79.78px" },
              backgroundColor: "#FFFFFF",
              borderRadius: { xs: "5px", md: "18px" },
              border: {
                xs: "4px solid #00FFA3",
                sm: "8px solid #00FFA3",
                md: "10px solid #00FFA3",
              },
              top: { xs: "200px", sm: "330px", md: "420px" },
              right: { xs: "5px", sm: "40px", md: "130px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={"600"}
              fontSize={{ xs: "14px", sm: "24px", md: "32px" }}
              gutterBottom
              color="#0E0E0E"
            >
              Invoice Discounting
            </Typography>
          </Box>

          {/* Content Box - Bottom Left */}
          <Box
            sx={{
              position: "absolute",
              width: { xs: "122px", sm: "30%", md: "285.99px" },
              height: { xs: "35.56px", sm: "70px", md: "79.78px" },
              backgroundColor: "#FFFFFF",
              borderRadius: { xs: "5px", md: "18px" },
              border: {
                xs: "4px solid #00FFA3",
                sm: "8px solid #00FFA3",
                md: "10px solid #00FFA3",
              },
              bottom: { xs: "90px", sm: "110px", md: "175px" },
              left: { xs: "25px", sm: "90px", md: "220px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1",

              //? padding: "10px",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={"600"}
              fontSize={{ xs: "14px", sm: "24px", md: "32px" }}
              gutterBottom
              color="#0E0E0E"
            >
              Real Estate
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FundUsage;
